import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Transition } from "@headlessui/react";
import { MenuIcon, XIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import Searchbar from "../Searchbar";
import { logoutAsync } from "../../../features/auth/authSlice";
import constants from "../../../constants.js";
import { useAuthPrompt } from "../../../features/auth/authHooks";

export function MobileMenuWithIcon() {
  const [showNavMenu, setShowNavMenu] = useState(false);

  useEffect(() => {
    const handleEscapeKeyPress = event => {
      const isEscapeKeyPressed = event.keyCode === 27;
      if (isEscapeKeyPressed) setShowNavMenu(false);
    };
    document.addEventListener("keydown", handleEscapeKeyPress);
    return () => document.removeEventListener("keydown", handleEscapeKeyPress);
  }, []);

  const handleClickOutside = () => {
    setShowNavMenu(false);
  };

  return (
    <div>
      <MobileMenuIcon setShowNavMenu={setShowNavMenu} />

      <MobileMenu
        isOpen={showNavMenu}
        setShowNavMenu={setShowNavMenu}
        handleClickOutside={handleClickOutside}
      />
    </div>
  );
}
function MobileMenuIcon({ setShowNavMenu }) {
  return (
    <div className="lg:hidden">
      <button
        type="button"
        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:bg-gray-800 focus:outline-none focus:bg-gray-800 focus:text-gray-500 transition duration-150 ease-in-out"
        onClick={() => setShowNavMenu(true)}
      >
        <MenuIcon className="h-6 w-6" />
      </button>
    </div>
  );
}
function MobileMenu({ isOpen, handleClickOutside, setShowNavMenu }) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(state => state.auth);
  const { platformContent, platformData } = useSelector(state => state.frontend);
  const { promptSignin } = useAuthPrompt();

  return (
    <Transition
      show={isOpen}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
      as="div"
      className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden z-20 min-h-screen"
      style={{
        zIndex: 50
      }}
    >
      <div className="absolute inset-0" onClick={handleClickOutside} />
      <div className="rounded-lg shadow-lg">
        <div
          className="rounded-lg shadow-xs bg-nav divide-y-2 divide-gray-800 absolute m-2 top-0 inset-x-0"
          style={{
            backgroundColor: `${platformData.heroBGColor}`,
            color: `${platformData.heroTextColor}`
          }}
        >
          <div className="pt-5 pb-6 px-5 space-y-6">
            <div className="flex items-center justify-between">
              <div>
                <Link to="/">
                  <img
                    className="h-8 w-auto sm:h-10"
                    src={constants.imgUrl + platformData.logo}
                    alt="Logo"
                  />
                </Link>
              </div>
              <div className="-mr-2">
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-800 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                  onClick={() => setShowNavMenu(false)}
                >
                  <XIcon className="h-6 w-6" />
                </button>
              </div>
            </div>
            <div>
              <nav className="grid gap-y-8">
                <Searchbar />
                {platformContent?.groups?.length > 0 && (
                  <Link
                    to="/groups"
                    onClick={() => setShowNavMenu(false)}
                    className="-m-3 p-3 flex items-center space-x-3 rounded-md transition ease-in-out duration-150"
                    style={{
                      color: `${platformData.heroTextColor}`
                    }}
                  >
                    <div
                      className="text-base leading-6 font-medium text-white"
                      style={{
                        color: `${platformData.heroTextColor}`
                      }}
                    >
                      Groups
                    </div>
                  </Link>
                )}
                {(
                  (platformData &&
                    platformData.Links &&
                    [...platformData.Links].sort((a, b) => {
                      if (a.position > b.position) return 1;
                      if (a.position < b.position) return -1;
                      return 0;
                    })) ||
                  []
                ).map((link, i) => (
                  <a
                    key={i}
                    href={link.url}
                    className="-m-3 p-3 flex items-center space-x-3 rounded-md transition ease-in-out duration-150"
                    target={link.url.includes("http") ? "_blank" : "_self"}
                    rel="noopener noreferrer"
                    onClick={() => setShowNavMenu(false)}
                    style={{
                      color: `${platformData.heroTextColor}`
                    }}
                  >
                    <div
                      className="text-base flex gap-2   leading-6 font-medium text-white"
                      style={{
                        color: `${platformData.heroTextColor}`
                      }}
                    >
                      {link.title}
                      {link.url.includes("http") && (
                        <ExternalLinkIcon className="w-5 opacity-50 h-5 mt-0.5" />
                      )}
                    </div>
                  </a>
                ))}

                {platformContent?.topics &&
                platformContent.topics.some(topic => topic.showInNav) ? (
                  <>
                    <div
                      className="border-t border-gray-600 pt-6"
                      style={{
                        color: `${platformData.heroTextColor}`
                      }}
                    >
                      TOPICS
                    </div>
                    {platformContent.topics.map(
                      topic =>
                        topic.showInNav && (
                          <a
                            key={topic.name}
                            href={"/topic/" + topic.slug}
                            onClick={() => setShowNavMenu(false)}
                            className="-m-3 p-3 block "
                          >
                            <p className="text-base font-medium">{topic.name}</p>
                            {/* <p className="mt-1 leading-4 text-sm text-gray-500">
                    {topic.description}
                    </p> */}
                          </a>
                        )
                    )}
                  </>
                ) : null}
              </nav>
            </div>
          </div>
          <div className="py-6 px-5 space-y-6">
            <div className="space-y-6">
              {!currentUser ? (
                <button
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white shadow-sm button transition ease-in-out duration-150"
                  style={{
                    backgroundColor: `${platformData.primaryColor}`,
                    color: `${platformData.lightColor}`
                  }}
                  onClick={() => {
                    promptSignin();
                    setShowNavMenu(false);
                  }}
                >
                  Sign in
                </button>
              ) : (
                <p className="text-center text-base leading-6 font-medium text-gray-500">
                  {currentUser.name}&nbsp;
                  <button
                    className="text-blue-600 hover:text-blue-500 transition ease-in-out duration-150"
                    onClick={() => dispatch(logoutAsync())}
                  >
                    Sign out
                  </button>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
