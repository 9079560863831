import qs from "qs";
import { useEffect, useRef } from "react";
import constants from "../constants.js";

export const stripHtmlTags = str => str?.replace(/<\/?[^>]+(>|$)/g, "") || "";

export const richTextToPlainText = str => {
  try {
    const parsed = JSON.parse(str);
    return parsed.map(block => block.data.text || block.data.items.join("\n")).join("\n");
  } catch {}
  return stripHtmlTags(str);
};

export const extractNotionPageIdFromUrl = url => {
  // in a notion page url like https://inovo.notion.site/Tribe-Releases-e7aab0beb40147e8bfe525e9e422ec2d
  // the page id is e7aab0beb40147e8bfe525e9e422ec2d
  const array = url.split("-");
  return array[array.length - 1];
};

export const composeQueryParams = (payloadParams, stateMetadata) => {
  const params = {
    currentPage: payloadParams.currentPage || stateMetadata.currentPage || 1,
    itemsPerPage: payloadParams.itemsPerPage || stateMetadata.itemsPerPage || 25,
    searchTerm: (payloadParams.searchTerm ?? stateMetadata.searchTerm) || undefined,
    filters: payloadParams.filters || stateMetadata.filters || undefined
  };
  if (payloadParams.sort === null) params.sort = null;
  else if (payloadParams.sort)
    params.sort = { [payloadParams.sort.column]: payloadParams.sort.desc ? "DESC" : "ASC" };
  else if (stateMetadata.sort)
    params.sort = { [stateMetadata.sort.column]: stateMetadata.sort.desc ? "DESC" : "ASC" };
  return params;
};
// allows axios to handle arrays in query params
export const paramsSerializer = params => qs.stringify(params, { arrayFormat: "brackets" });

export const initialDashboardPageMetadata = {
  currentPage: 1,
  itemsPerPage: 25,
  totalItems: 1,
  totalPages: 1,
  firstItem: 1,
  lastItem: 1,
  sort: null,
  filters: null,
  searchTerm: "",
  hasMore: true
};

export const mergeDshbPageMetadataObjects = (stateMetadata, payloadMetadata) => {
  const { currentPage, itemsPerPage, totalItems } = payloadMetadata;
  const firstItem = (currentPage - 1) * itemsPerPage + 1;
  const lastItem = currentPage * itemsPerPage;
  const updatedMetadata = {
    ...stateMetadata,
    ...payloadMetadata,
    firstItem,
    lastItem: lastItem < totalItems ? lastItem : totalItems
  };
  if (!payloadMetadata.filters) updatedMetadata.filters = null;
  if (payloadMetadata.sort) {
    const [column, desc] = Object.entries(payloadMetadata.sort)[0];
    updatedMetadata.sort = { column, desc: desc === "DESC" };
  }
  return updatedMetadata;
};

export const toggleSort = (currentSort, col) => {
  let newSort;
  // first time sort is clicked, set sort to column and descending
  if (col !== currentSort?.column) newSort = { column: col, desc: false };
  // if already sorted by this column, toggle direction
  else if (!currentSort?.desc) newSort = { column: col, desc: true };
  // if already sorted by this column in descending order, clear sort
  else newSort = null;
  return newSort;
};

export const defaultNoPaginationOptions = { itemsPerPage: 999, currentPage: 1, searchTerm: "" };

export const getImageSrc = path => (path ? constants.imgUrl + path : "/anon.png");

export function getImageSrcRelOrAbsolute(photo, optimizationQuery = "") {
  if (!photo) return "/anon.png";
  // either contains http and return directly
  if (photo.includes("http")) return photo;
  // or return this:
  return constants.imgUrl + photo + optimizationQuery;
}

// for troubleshooting re-renders from props changes
export function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log("Changed props:", changedProps);
    }
    prev.current = props;
  });
}
