export const cdnUrl = process.env.REACT_APP_CDN_URL;
export const imgUrl = process.env.REACT_APP_IMG_URL;
export const RAYGUN_API_KEY = process.env.REACT_APP_RAYGUN_API_KEY;
export const NODE_NAME = process.env.REACT_APP_RAYGUN_TAG_NODE;

const urls = {
  cdnUrl,
  imgUrl
};

export default urls;
