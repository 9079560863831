let windowLocalStorage;
try {
  // try because if localStorage is disabled, it will throw an error and crash the app
  // this can happen if the page is loaded in another domain, e.g. embed in an iframe
  windowLocalStorage = window.localStorage;
} catch (e) {
  // do nothing
}

export default windowLocalStorage;
