import { createContext, useState } from "react";

export const LeftNavbarVisibleContext = createContext(null);

export const LeftNavbarVisibleProvider = ({ children }) => {
  const [isLeftNavbarVisible, setIsLeftNavbarVisible] = useState(false);

  return (
    <LeftNavbarVisibleContext.Provider
      value={{
        isLeftNavbarVisible,
        setIsLeftNavbarVisible
      }}
    >
      {children}
    </LeftNavbarVisibleContext.Provider>
  );
};
