import windowLocalStorage from "./localStorage";

export function lazyImportWrapper(fn, retriesLeft = 1, interval = 100) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          // try loading the resource once more
          if (retriesLeft > 0)
            return lazyImportWrapper(fn, retriesLeft - 1, interval).then(resolve, reject);

          // if that didn't work, see if the error was ChunkLoadError and do a hard refresh
          if (isChunkLoadError(error) && shouldRefresh()) {
            window.location.reload();
            return resolve();
          }

          // if that didn't work, propagate the error up
          return reject(error);
        }, interval);
      });
  });
}

function isChunkLoadError(error) {
  // known bug that can be solved by a hard refresh
  // https://www.notion.so/inovo/9e6f16dcf1e6472f8ccaa8cd7900a9fe
  const chunkFailedMessage = /Loading (CSS )?chunk .+ failed/;
  return error?.message && chunkFailedMessage.test(error.message);
}

function shouldRefresh() {
  // protect against infinite refresh loop (not 100% safe, ie. if the page blocks localStorage access)
  if (!getWithExpiry("chunk_failed")) {
    // save to local storage with a 10 second expiration
    setWithExpiry("chunk_failed", "true", 10000);
    return true;
  }
}

function setWithExpiry(key, value, ttl) {
  if (!windowLocalStorage) return;
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl
  };
  windowLocalStorage?.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
  if (!windowLocalStorage) return null;
  const itemString = windowLocalStorage?.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    windowLocalStorage?.removeItem(key);
    return null;
  }

  return item.value;
}
