import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  composeQueryParams,
  initialDashboardPageMetadata,
  mergeDshbPageMetadataObjects,
  paramsSerializer
} from "../../utils";

export const useGroupOptions = () => {
  const dispatch = useDispatch();
  const { groupOptions } = useSelector(state => state.group);
  const { currentUser } = useSelector(state => state.auth);

  useEffect(() => {
    if (currentUser.role !== "creator" && currentUser.role !== "admin") return;
    if (!groupOptions.length) {
      dispatch(getGroupOptionsAsync());
    }
    // ignore groupOptions as it will be updated by getGroupOptionsAsync (avoid infinite re-renders)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentUser]);

  return groupOptions.map(group => ({ id: group.id, label: group.name }));
};

export const readAllGroupsForDashboardAsync = createAsyncThunk(
  "group/readAll",
  async (payload = {}, { getState }) => {
    try {
      const { metadata } = getState().group;
      const params = composeQueryParams(payload, metadata);
      const response = await axios.get("/api/admin/groups", { params, paramsSerializer });
      return { groups: response.data };
    } catch (error) {
      console.log(error);
    }
  }
);

export const readMyGroupsAsync = createAsyncThunk("group/myGroups", async () => {
  try {
    const response = await axios.get(`/api/user/groups`);
    return { myGroups: response.data };
  } catch (error) {
    console.log(error);
  }
});

export const upsertGroupAsync = createAsyncThunk(
  "group/upsert",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`/api/group`, payload.group);
      dispatch(getGroupOptionsAsync());
      return { group: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const deleteGroupAsync = createAsyncThunk("/group/delete", async (payload, { dispatch }) => {
  await axios.delete(`/api/group/${payload.group.id}`);
  dispatch(getGroupOptionsAsync());
  return { id: payload.group.id };
});

export const getGroupsByPlatformAsync = createAsyncThunk(
  "/group/getGroupsByPlatform",
  async payload => {
    const response = await axios.get(`/api/groups/platform/${payload.PlatformId}`);
    return { groups: response.data };
  }
);

export const getGroupOptionsAsync = createAsyncThunk("/group/getGroupOptions", async () => {
  const response = await axios.get(`/api/group-options`);
  return { groups: response.data };
});

export const groupSlice = createSlice({
  name: "group",
  initialState: {
    loading: true,
    error: null,
    // groups array is used both for frontend (groups list) and for admin dashboard
    groups: [],
    groupOptions: [],
    myGroups: [],
    metadata: initialDashboardPageMetadata
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(readAllGroupsForDashboardAsync.pending, state => {
        state.loading = true;
      })
      .addCase(readAllGroupsForDashboardAsync.fulfilled, (state, action) => {
        state.loading = false;
        const { items, ...metadata } = action.payload.groups || {};
        state.groups = items || [];
        state.metadata = mergeDshbPageMetadataObjects(state.metadata, metadata);
      })
      .addCase(readAllGroupsForDashboardAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(readMyGroupsAsync.pending, state => {
        state.loading = true;
      })
      .addCase(readMyGroupsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.myGroups = action?.payload?.myGroups;
      })
      .addCase(readMyGroupsAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getGroupsByPlatformAsync.pending, state => {
        state.loading = true;
      })
      .addCase(getGroupsByPlatformAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = action.payload.groups;
      })
      .addCase(getGroupsByPlatformAsync.rejected, state => {
        state.loading = false;
      })
      .addCase(getGroupOptionsAsync.fulfilled, (state, action) => {
        state.groupOptions = action.payload.groups;
      })
      .addCase(upsertGroupAsync.pending, state => {
        state.loading = true;
      })
      .addCase(upsertGroupAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.group[1]) {
          // insert
          if (action.payload.group[0]) state.groups.unshift(action.payload.group[0]);
        } else {
          // update
          state.groups = (state.groups || []).map(group =>
            group.id === action.payload.group[0].id ? action.payload.group[0] : group
          );
        }
      })
      .addCase(upsertGroupAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload || []).reduce((acc, error) => {
          acc[error.path] = `The ${error.path} field cannot be empty`;
          return acc;
        }, {});
      })
      .addCase(deleteGroupAsync.pending, state => {
        state.loading = true;
      })
      .addCase(deleteGroupAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.groups = state.groups.filter(group => group.id !== action.payload.id);
      })
      .addCase(deleteGroupAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  }
});
export const { clearError, clearErrors } = groupSlice.actions;
export default groupSlice.reducer;
