function Tab({ title, activeTab, onChange }) {
  return (
    <button
      className={`py-2 px-3 block hover:text-dark-blue-200 focus:outline-none ${
        activeTab ? "text-dark-blue-200 border-b-2 font-bold border-dark-blue-200" : ""
      }`}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onChange();
      }}
    >
      {title}
    </button>
  );
}

export default function TabNav({ tabs, onChange, activeTab }) {
  return (
    <div className="flex flex-row mt-4">
      {tabs.map((tab, idx) => (
        <Tab title={tab} activeTab={activeTab === tab} onChange={() => onChange(tab)} key={idx} />
      ))}
    </div>
  );
}
