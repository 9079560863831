import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Transition } from "@headlessui/react";
import { logoutAsync } from "../../../features/auth/authSlice";
import { getImageSrcRelOrAbsolute } from "../../../utils";

export function ProfileImageWithMenu({ onEditProfile }) {
  const [profileDropdownIsOpen, setProfileDropdownIsOpen] = useState(false);

  useEffect(() => {
    const handleEscapeKeyPress = event => {
      const isEscapeKeyPressed = event.keyCode === 27;
      if (isEscapeKeyPressed) setProfileDropdownIsOpen(false);
    };
    document.addEventListener("keydown", handleEscapeKeyPress);
    // This event listener causes the log out functionnality to randomly work
    // document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleEscapeKeyPress);
      // document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = () => {
    setProfileDropdownIsOpen(false);
  };

  return (
    <div className="ml-3 w-10 relative">
      <ProfileImage onClick={() => setProfileDropdownIsOpen(!profileDropdownIsOpen)} />

      <ProfileMenu
        onEditProfile={onEditProfile}
        isOpen={profileDropdownIsOpen}
        handleClickOutside={handleClickOutside}
      />
      {/* Clickable background to dismiss menu */}
      {profileDropdownIsOpen && <div className="fixed inset-0 z-10" onClick={handleClickOutside} />}
    </div>
  );
}
function ProfileImage({ onClick }) {
  const { currentUser } = useSelector(state => state.auth);

  return (
    <button
      className="flex text-sm h-8 w-8 border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
      id="user-menu"
      aria-label="User menu"
      aria-haspopup="true"
      onClick={onClick}
    >
      <img
        id="user-menu-img"
        className="w-8 h-8 rounded-full object-cover"
        src={getImageSrcRelOrAbsolute(currentUser.photoUrl, "?tr=w-96,h-96")}
        alt=""
      />
    </button>
  );
}
function ProfileMenu({ onEditProfile, isOpen, handleClickOutside }) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(state => state.auth);
  const { platformData } = useSelector(state => state.frontend);
  return (
    <Transition
      show={isOpen}
      enter="transition ease-out duration-200"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
      className="origin-top-right absolute right-1 top-10 w-48 rounded-md overflow-hidden shadow-lg bg-white z-20"
    >
      <div role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
        {("admin" === currentUser.role || "creator" === currentUser.role) && (
          <Link
            to="/dashboard"
            target="_blank"
            className="w-full block text-center px-4 py-3 text-sm text-slate-700 bg-white hover:bg-slate-100 transition ease-in-out duration-150"
            role="menuitem"
          >
            Dashboard
          </Link>
        )}

        <button
          className="w-full px-4 py-3 text-sm text-slate-700 bg-white hover:bg-slate-100 transition ease-in-out duration-150"
          role="menuitem"
          onClick={() => {
            onEditProfile();
            handleClickOutside();
          }}
        >
          Edit Profile
        </button>

        {platformData.supportEmail && (
          <a
            className="w-full block text-center px-4 py-3 text-sm text-slate-700 bg-white hover:bg-slate-100 transition ease-in-out duration-150"
            role="menuitem"
            href={`mailto:${platformData.supportEmail}`}
          >
            Contact Support
          </a>
        )}
        <button
          className="w-full px-4 py-3 text-sm text-slate-700 bg-white hover:bg-slate-100 transition ease-in-out duration-150"
          role="menuitem"
          onClick={() => dispatch(logoutAsync())}
        >
          Log out
        </button>
      </div>
    </Transition>
  );
}
