import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import ContentComplete from "../../content/ContentComplete";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronLeftIcon, PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/solid";
import ContentLink from "../../../general/ContentLink";

const GroupContentList = ({ groupSlug, currentUser }) => {
  const [collections, setCollections] = useState({ items: [] });
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const { platformData } = useSelector(state => state.frontend);

  useEffect(() => {
    axios
      .get(`/api/group/${groupSlug}/collections`, { params: { itemsPerPage } })

      .then(resp => {
        resp.data.items = resp.data.items.slice().sort((a, b) => a.position - b.position);
        setCollections(resp.data);
      })
      .catch(err => {
        console.log(err);
        setCollections({ items: [] });
      });
  }, [groupSlug, itemsPerPage, currentUser]);

  return (
    <div className="mt-8 pb-6">
      {window.location.pathname.includes(groupSlug + "/") && (
        <Link
          to={`/groups/${groupSlug}`}
          className=" flex mb-4 mt-4 text-xl font-bold relative items-center  border-b border-gray-400 py-2"
        >
          <ChevronLeftIcon className="w-5 h-5" />
          Group Home
        </Link>
      )}

      {collections.items.map(collection => {
        if (moment().isBefore(collection.publishedDate)) return null;
        if (moment().isAfter(collection.expireDate)) return null;
        return (
          <Disclosure key={collection.id}>
            {({ open }) => (
              <div
                className=" mb-3 p-5 hover:opacity-100 bg-white shadow-md  rounded-md  relative"
                style={{
                  color: `${platformData.primaryColor}`,
                  backgroundColor: `${platformData.lightColor}`
                }}
              >
                <Disclosure.Button
                  className={`${
                    open ? "opacity-100  " : "  text-gray-600 opacity-60"
                  }   border-b  border-gray-100 border-none flex items-center justify-between box-border w-full focus:outline-none focus:border-0  `}
                >
                  <div className=" flex-grow text-left">
                    <h4
                      className={`${
                        open ? "text-blue-500" : "opacity-80"
                      } font-bold text-base leading-4`}
                      style={{
                        color: `${open ? platformData.primaryColor : platformData.darkColor}`
                      }}
                    >
                      {collection.name}
                      {/* <span className="font-normal text-xs  rounded-full text-gray-200 bg-gray-100 text-center w-5 h-5 inline-block">
                        {collection.Contents.length}
                      </span> */}
                    </h4>
                  </div>
                  <div>
                    {open ? (
                      <MinusCircleIcon
                        className="w-5 h-5 text-blue-500"
                        style={{ color: `${platformData.primaryColor}` }}
                      />
                    ) : (
                      <PlusCircleIcon
                        className="w-5 h-5 text-gray-500"
                        style={{ color: `${platformData.darkColor}` }}
                      />
                    )}
                  </div>
                </Disclosure.Button>

                <Transition
                  enter="transition duration-150 ease-out"
                  enterFrom="transform translate-y-4 opacity-0"
                  enterTo="transform translate-y-0 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform translate-y-0 opacity-100"
                  leaveTo="transform translate-y-4 opacity-0"
                >
                  <Disclosure.Panel>
                    <div className="mt-3">
                      {(collection.Contents || []).map((content, i) => (
                        <div
                          className="flex items-stretch space-x-2 mb-3 text-red-500"
                          key={content.id}
                          style={{
                            color: `${platformData.darkColor}`
                          }}
                        >
                          {/* wrap in flex div to avoid squishing the circle when title is long */}
                          <div className="flex">
                            <ContentComplete
                              content={content}
                              buttonClass={"text-xs rounded-full h-5 w-5"}
                              platformData={platformData}
                              iconOnly
                            />
                          </div>
                          <ContentLink
                            isInternal={content.type === "internallink"}
                            to={
                              content.type === "internallink"
                                ? content.contentURI
                                : `/groups/${groupSlug}/${collection.slug}/${content.id}`
                            }
                          >
                            <div className="flex  flex-col">
                              <p className="pt-0.5 text-base font-normal leading-none flex items-center flex-row">
                                {content.title}
                              </p>
                            </div>
                          </ContentLink>
                        </div>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </Transition>
                {/* <Link to={`/${collection.slug}`} className="w-full text-center text-dark-blue-200   ">
                View all
              </Link> */}
              </div>
            )}
          </Disclosure>
        );
      })}
      {collections.totalItems > itemsPerPage && (
        <button
          onClick={() => setItemsPerPage(itemsPerPage + 20)}
          className="text-white bg-gray-800"
        >
          Load More...
        </button>
      )}
    </div>
  );
};

export default GroupContentList;
