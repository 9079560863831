import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  composeQueryParams,
  initialDashboardPageMetadata,
  mergeDshbPageMetadataObjects,
  paramsSerializer
} from "../../utils";
import { getTagByPlatformAsync } from "../content/contentSlice";

export const readAllTagsAsync = createAsyncThunk(
  "tag/readAll",
  async (payload = {}, { getState }) => {
    try {
      const { metadata } = getState().tag;
      const params = composeQueryParams(payload, metadata);
      const response = await axios.get("/api/tag", { params, paramsSerializer });
      return { tags: response.data };
    } catch (error) {
      console.log(error);
    }
  }
);

export const upsertTagAsync = createAsyncThunk(
  "tag/upsert",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/tag`, payload.tag);
      return { tag: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const quickTagCreateAsync = createAsyncThunk(
  "tag/quickCreate",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const { frontend } = getState();
    const platformId = payload.PlatformId || frontend.platformData.id;
    const body = {
      title: payload.title,
      PlatformId: platformId
    };
    try {
      const response = await axios.post(`/api/tag`, body);
      await dispatch(getTagByPlatformAsync({ PlatformId: platformId }));
      return { tag: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const deleteTagAsync = createAsyncThunk("/tag/delete", async payload => {
  await axios.delete(`/api/tag/${payload.tag.id}`);
  return { id: payload.tag.id };
});

export const tagSlice = createSlice({
  name: "tag",
  initialState: {
    loading: false,
    error: null,
    tags: [],
    metadata: initialDashboardPageMetadata
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(readAllTagsAsync.pending, state => {
        state.loading = true;
      })
      .addCase(readAllTagsAsync.fulfilled, (state, action) => {
        state.loading = false;
        const { items, ...metadata } = action.payload.tags || {};
        state.tags = items || [];
        state.metadata = mergeDshbPageMetadataObjects(state.metadata, metadata);
      })
      .addCase(readAllTagsAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(upsertTagAsync.pending, state => {
        state.loading = true;
      })
      .addCase(quickTagCreateAsync.pending, state => {
        state.loading = true;
      })
      .addCase(quickTagCreateAsync.fulfilled, state => {
        state.loading = false;
      })
      .addCase(quickTagCreateAsync.rejected, state => {
        state.loading = false;
      })
      .addCase(upsertTagAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.tag[1]) {
          // insert
          if (action.payload.tag[0]) state.tags.unshift(action.payload.tag[0]);
        } else {
          // update
          state.tags = (state.tags || []).map(tag =>
            tag.id === action.payload.tag[0].id ? action.payload.tag[0] : tag
          );
        }
      })
      .addCase(upsertTagAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload || []).reduce((acc, error) => {
          acc[error.path] = `The ${error.path} field cannot be empty`;
          return acc;
        }, {});
      })
      .addCase(deleteTagAsync.pending, state => {
        state.loading = true;
      })
      .addCase(deleteTagAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.tags = state.tags.filter(tag => tag.id !== action.payload.id);
      })
      .addCase(deleteTagAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  }
});
export const { clearError, clearErrors } = tagSlice.actions;
export default tagSlice.reducer;
