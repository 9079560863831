import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { SearchIcon } from "@heroicons/react/outline";
import queryString from "query-string";
import {
  executeSearchAsync,
  setSearchQuery,
  setFocus,
  clearFocus
} from "../../features/search/searchSlice";

const Searchbar = () => {
  const dispatch = useDispatch();
  const previousQuery = useRef("");
  const history = useHistory();
  const location = useLocation();
  const searchState = useSelector(state => state.search);
  const { platformData } = useSelector(state => state.frontend);
  const { query, hasFocus } = searchState;
  const [localQuery, setLocalQuery] = useState("");

  useEffect(() => {
    if (localQuery.length) {
      return;
    }
    setLocalQuery(query);
  }, [query, localQuery.length]);

  useEffect(() => {
    if (location.pathname !== "/search" && !query?.length && !hasFocus) {
      setLocalQuery("");
      return;
    }

    if (typeof platformData.id !== "number") {
      return;
    }

    const { query: queryParam } = queryString.parse(location.search);
    if (location.pathname === "/search" && !hasFocus && localQuery !== queryParam) {
      setLocalQuery(queryParam);
      previousQuery.current = queryParam;
      dispatch(executeSearchAsync({ resetFocus: false, query: queryParam }));
    }
  }, [location.pathname, location.search, platformData.id, query, dispatch, hasFocus, localQuery]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleEnter = e => {
    if (e.key === "Enter") {
      dispatch(setSearchQuery(localQuery));
      dispatch(executeSearchAsync({ query: localQuery }));
      history.push(`/search?query=${query}`);
    }
  };

  const focusHandler = () => {
    dispatch(setFocus());
  };

  const blurHandler = () => {
    dispatch(clearFocus());
  };

  const changeHandler = e => {
    setLocalQuery(e.target.value);
    dispatch(setSearchQuery(e.target.value));
  };

  return (
    <div
      className="rounded-full mx-2 border flex items-center"
      style={{ borderColor: platformData.heroTextColor }}
    >
      <div className="m-0 rounded-full p-2 focus:outline-none w-8 h-8 flex items-center justify-center">
        <SearchIcon className="h-6 w-6" />
      </div>
      <input
        type="text"
        name="search"
        className="focus:border-none focus:outline-none bg-transparent platform-hero-text"
        placeholder="Search..."
        value={localQuery}
        onChange={changeHandler}
        onKeyPress={handleEnter}
        onFocus={focusHandler}
        onBlur={blurHandler}
      />
      <div className="p-0"></div>
    </div>
  );
};

export default Searchbar;
