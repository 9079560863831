import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import "video.js/dist/video-js.css";
import store from "./app/store";
import ErrorBoundary from "./components/ErrorBoundary";
import App from "./App";
import { initErrorReportingIfEnabled } from "./utils/errorReporting";

initErrorReportingIfEnabled();

const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ErrorBoundary name="top">
      <Router>
        <App />
      </Router>
    </ErrorBoundary>
  </Provider>
);
