import { configureStore } from "@reduxjs/toolkit";
import platformReducer from "../features/platform/platformSlice";
import toastNotificationReducer from "../features/toast-notification/toastNotificationSlice";
import userReducer from "../features/user/userSlice";
import collectionReducer from "../features/collection/collectionSlice";
import tagReducer from "../features/tag/tagSlice";
import topicReducer from "../features/topic/topicSlice";
import groupReducer from "../features/group/groupSlice";
import contentReducer from "../features/content/contentSlice";
import linkReducer from "../features/link/linkSlice";
import adReducer from "../features/ad/adSlice";
import frontendReducer from "../features/frontend/frontendSlice";
import searchReducer from "../features/search/searchSlice";
import authReducer from "../features/auth/authSlice";
import chatReducer from "../features/chat/chatSlice";
import courseReducer from "../features/course/courseSlice";

// export to allow use in createStoreWithPreloadedState (test-utils.js)
export const reducer = {
  platform: platformReducer,
  toastNotification: toastNotificationReducer,
  user: userReducer,
  content: contentReducer,
  collection: collectionReducer,
  topic: topicReducer,
  link: linkReducer,
  ad: adReducer,
  frontend: frontendReducer,
  auth: authReducer,
  chat: chatReducer,
  group: groupReducer,
  tag: tagReducer,
  search: searchReducer,
  course: courseReducer
};

export default configureStore({
  reducer
});
