import React, { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Searchbar from "../Searchbar";
import { checkAuthStatusAsync } from "../../../features/auth/authSlice";
import constants from "../../../constants.js";
import FormModal from "../../forms/FormModal";
import UserForm from "../../forms/UserForm";
import { useAuthPrompt } from "../../../features/auth/authHooks";
import { ProfileImageWithMenu } from "./ProfileImageWithMenu";
import { MobileMenuWithIcon } from "./MobileMenuWithIcon";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TopNavbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { promptSignin } = useAuthPrompt();
  const { myGroups } = useSelector(state => state.group);
  const {
    platformContent,
    platformData,
    loading: isPlatformLoading
  } = useSelector(state => state.frontend);
  const { currentUser, loading: isAuthLoading } = useSelector(state => state.auth);
  const [isUserEditOpen, setUserEditOpen] = useState(false);
  const topNavPlatformColors = {
    backgroundColor: `${platformData.heroBGColor}`,
    color: `${platformData.heroTextColor}`
  };

  const isLoadingDone = !isPlatformLoading && !isAuthLoading;

  const shouldHideTopNavbar =
    location.pathname.includes("/dashboard") ||
    location.pathname.includes("/auth") ||
    location.pathname.includes("/embed");

  if (shouldHideTopNavbar) return null;

  return (
    <div id="navtop" className="top-0 bg-nav z-50 fixed w-full h-16" style={topNavPlatformColors}>
      <div className="flex h-full mx-auto lg:space-x-4 space-x-0 justify-between items-center px-2 sm:px-6">
        {platformData && platformData.logo && (
          <Link to="/">
            <img
              className="max-h-8 sm:max-h-10 pr-6"
              src={constants.imgUrl + platformData.logo + "?tr=h-60"}
              alt="Logo"
            />
          </Link>
        )}

        <nav className="hidden lg:flex ">
          {myGroups?.length > 0 && (
            <Link
              to="/groups"
              className="ml-4 px-3 py-2 rounded-md text-base  font-medium leading-5 text-white  hover:bg-gray-800 hover:no-underline focus:outline-none transition duration-150 ease-in-out"
              style={{
                backgroundColor: `${platformData.heroBGColor}`,
                color: `${platformData.heroTextColor}`
              }}
            >
              Groups
            </Link>
          )}
          {(
            (platformData &&
              platformData.Links &&
              [...platformData.Links].sort((a, b) => {
                if (a.position > b.position) return 1;
                if (a.position < b.position) return -1;
                return 0;
              })) ||
            []
          ).map((link, i) => (
            <a
              key={i}
              href={link.url}
              className="ml-4 px-2 md:px-4 py-2 rounded-md text-base font-medium leading-5 text-white  hover:bg-gray-800 hover:no-underline focus:outline-none transition duration-150 ease-in-out"
              target={link.url.includes("http") ? "_blank" : "_self"}
              rel="noopener noreferrer"
              style={{
                backgroundColor: `${platformData.heroBGColor}`,
                color: `${platformData.heroTextColor}`
              }}
            >
              {link.title}
            </a>
          ))}

          {platformContent?.topics && platformContent.topics.some(topic => topic.showInNav) ? (
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-white" : "text-white",
                      "group ml-4 px-3 py-2 rounded-md inline-flex leading-5 text-base font-medium hover:text-gray-900 hover:no-underline focus:outline-none transition duration-150 ease-in-out"
                    )}
                    style={{
                      backgroundColor: `${platformData.heroBGColor}`,
                      color: `${platformData.heroTextColor}`
                    }}
                  >
                    <span>Topics</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-2 h-5 w-5 group-hover:text-gray-500"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {platformContent?.topics &&
                            platformContent.topics.map(
                              topic =>
                                topic.showInNav && (
                                  <a
                                    key={topic.name}
                                    href={"/topic/" + topic.slug}
                                    className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                                  >
                                    <p className="text-base font-medium text-gray-900">
                                      {topic.name}
                                    </p>
                                    <p className="mt-1 leading-4 text-sm text-gray-500">
                                      {topic.description}
                                    </p>
                                  </a>
                                )
                            )}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          ) : (
            false
          )}
        </nav>
        <div className="hidden w-xl sm:flex">
          <Searchbar />
        </div>

        {isLoadingDone && (
          <div className="items-center flex">
            {currentUser ? (
              <>
                {("admin" === currentUser.role || "creator" === currentUser.role) && (
                  <Link
                    to="/dashboard"
                    target="_blank"
                    className="ml-4 px-3 hidden   xl:flex py-2 rounded-md text-base font-medium leading-5 text-white  hover:bg-gray-800 hover:no-underline focus:outline-none transition duration-150 ease-in-out"
                    style={{
                      backgroundColor: `${platformData.heroBGColor}`,
                      color: `${platformData.heroTextColor}`
                    }}
                  >
                    Dashboard
                  </Link>
                )}
                <ProfileImageWithMenu onEditProfile={() => setUserEditOpen(true)} />
                <MobileMenuWithIcon />
              </>
            ) : (
              !platformData.hideSignin && (
                <>
                  <button
                    className="md:inline-flex mr-2 px-3 py-2 rounded-md text-base font-medium leading-5 text-white whitespace-nowrap hover:bg-gray-800 hover:no-underline focus:outline-none transition duration-150 ease-in-out"
                    style={{
                      color: `${platformData.heroTextColor}`,
                      backgroundColor: `${platformData.primaryColor}`
                    }}
                    onClick={promptSignin}
                  >
                    Sign in
                  </button>
                  <MobileMenuWithIcon />
                </>
              )
            )}
          </div>
        )}
      </div>
      <FormModal
        modalShowing={isUserEditOpen}
        closeModal={() => setUserEditOpen(false)}
        Form={UserForm}
        title="Edit your profile"
        model={currentUser}
        handleSubmitted={() => {
          dispatch(checkAuthStatusAsync());
        }}
      />
    </div>
  );
};

export default TopNavbar;
