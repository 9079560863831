import { useSelector } from "react-redux";
import { useIsLeftNavbarVisible } from "../../general/hooks/useIsLeftNavbarVisible";

export function TwoColumnLayout({ children, platformColors }) {
  // TODO: move platform colors up to the app-level
  const { platformData } = useSelector(state => state.frontend);
  const style = platformColors
    ? {
        backgroundColor: `${platformData.lightColor}`,
        color: `${platformData.darkColor}`
      }
    : {};

  return (
    <div id="two-column-layout" className="flex w-full h-full" style={style}>
      {children}
    </div>
  );
}

export function MainColumn({ children }) {
  const { isLeftNavbarVisible } = useIsLeftNavbarVisible();

  return <div className={`w-full pt-16 ${isLeftNavbarVisible ? "lg:pl-80" : ""}`}>{children}</div>;
}
