import React, { useEffect } from "react";
import { Transition } from "@tailwindui/react";
import { clearToastNotification } from "../../../../features/toast-notification/toastNotificationSlice";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircleIcon, XIcon } from "@heroicons/react/outline";

const ToastNotification = () => {
  const dispatch = useDispatch();
  const notification = useSelector(state => state.toastNotification);

  const isErrorType = notification.type === "error";
  // use success color and messages as the default / fallback
  const colorStyle = isErrorType ? "text-red-200" : "text-green-200";
  const defaultToastTitle = isErrorType ? "Error" : "Success!";
  const defaultToastDetails = isErrorType
    ? "Something went wrong."
    : "Your changes have been successfully saved.";
  const toastTitle = notification.title || defaultToastTitle;
  const toastDetails = notification.details || defaultToastDetails;

  useEffect(() => {
    if (!notification.showing) return;
    const timeout = setTimeout(() => dispatch(clearToastNotification()), 5000);
    return () => clearTimeout(timeout);
  }, [dispatch, notification]);

  return (
    <div className="fixed z-100 inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
      <Transition
        show={notification.showing}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        as="div"
        className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto"
      >
        <div className="rounded-lg shadow-xs overflow-hidden">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <CheckCircleIcon className={`h-6 w-6 ${colorStyle}`} />
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className={`text-sm leading-5 font-medium ${colorStyle}`}>{toastTitle}</p>
                <p className={`mt-1 text-sm leading-5 ${colorStyle}`}>{toastDetails}</p>
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                  onClick={() => dispatch(clearToastNotification())}
                  data-testid="dismiss-notification"
                >
                  <XIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default ToastNotification;
