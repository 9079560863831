import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getPlatformDataAsync } from "../frontend/frontendSlice";

// selectors

export const selectFathomAnalyticsUrl = state => {
  const platform = state.frontend.platformData;
  const fathomAnalyticsId = platform?.fathomAnalytics;
  if (!fathomAnalyticsId) return null;

  const homepageUrl = platform?.homepageUrl;
  // example: `https://app.usefathom.com/share/sqivptpz/inovo.io`
  const analyticsUrl = `https://app.usefathom.com/share/${fathomAnalyticsId}/${homepageUrl}`;

  return analyticsUrl;
};

// actions

export const upsertPlatformAsync = createAsyncThunk(
  "platform/upsert",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`/api/platform`, payload.platform);
      // refresh platform data
      dispatch(getPlatformDataAsync({ subdomain: response.data[0].slug }));
      return { platform: response.data };
    } catch (error) {
      console.error("Failed to upsert platform", error);
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const platformSlice = createSlice({
  name: "platform",
  initialState: {
    loading: true,
    error: null
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(upsertPlatformAsync.pending, state => {
        state.loading = true;
      })
      .addCase(upsertPlatformAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(upsertPlatformAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});
export const { clearError, clearErrors } = platformSlice.actions;
export default platformSlice.reducer;
