import axios from "axios";

export async function uploadFile({ file, onProgress = () => {} }) {
  try {
    const encodedFileName = encodeURIComponent(file.name);
    const response = await axios.get(`/get-signed-url?file-name=${encodedFileName}`);
    const { signedRequest, url } = response.data;

    await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.upload.onprogress = e => onProgress(getProgress(e));
      xhr.open("PUT", signedRequest);
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) return;
        if (xhr.status === 200) resolve();
        else reject();
      };
      xhr.send(file);
    });

    return url;
  } catch (error) {
    console.dir(error);
    return { error: "Could not upload file" };
  }
}

function getProgress(xhrProgressEvent) {
  const { loaded, total } = xhrProgressEvent;
  return ((loaded / total) * 100).toFixed(2);
}

// seems to work fine as is, but to make this production ready
// it would need error handling, concurrency, retries...
// see https://www.notion.so/inovo/600c95cef40e4b4eb55edca384d45dc9
export async function experimental_uploadFileInMultipleParts({ file }) {
  const fileName = file.name;
  const fileType = file.type;

  // Step 1: Initiate the multipart upload
  const res = await axios.post("/create-multipart-upload", { fileName });
  const { uploadId, filePath } = res.data;

  const chunkSize = 64 * 1024 * 1024; // 64MB as per Storj recommendation
  const numParts = Math.ceil(file.size / chunkSize);
  const parts = [];

  for (let partNumber = 1; partNumber <= numParts; partNumber++) {
    console.log(`Uploading part ${partNumber} of ${numParts}`);
    const start = (partNumber - 1) * chunkSize;
    const end = Math.min(start + chunkSize, file.size);
    const blob = file.slice(start, end);

    // Step 2: Get a pre-signed URL for each part
    const presignedUrlResponse = await axios.get("/signed-url-multipart-upload-part", {
      params: {
        "file-path": filePath,
        "upload-id": uploadId,
        "part-number": partNumber
      }
    });
    const presignedUrl = presignedUrlResponse.data.signedRequest;

    // Step 3: Upload each part to S3
    // Note: not sure if using axios vs XMLHttpRequest makes a difference here
    // in the past we've had issues with axios that were solved by using XMLHttpRequest
    const res = await axios.put(presignedUrl, blob, { headers: { "Content-Type": fileType } });

    parts.push({ ETag: res.headers.etag, PartNumber: partNumber });
  }

  // Step 4: Complete the multipart upload
  await axios.post("/complete-multipart-upload", { filePath, uploadId, parts });

  return filePath;
}
