import rg4js from "raygun4js";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RAYGUN_API_KEY, NODE_NAME } from "../constants";
import packageJson from "../../package.json";

export const initErrorReportingIfEnabled = () => {
  if (!RAYGUN_API_KEY) return;

  rg4js("apiKey", RAYGUN_API_KEY);
  rg4js("enableCrashReporting", true);
  // rg4js("enablePulse", true); // Real User Monitoring
  rg4js("setVersion", packageJson.version);
  rg4js("options", {
    ignoreAjaxAbort: true,
    debugMode: false,
    excludedHostnames: ["localhost", "127.0.0.1"],
    excludedUserAgents: ["PhantomJS", "MSIE"]
  });
  // tags help filtering errors, this one allows filtering by tribe node (e.g. tribe-prod, 100X)
  rg4js("withTags", [`node:${NODE_NAME}`]);
  // it may be a good idea to create tag by platform once that data is loaded
};

export const useWatchCurrentUserForErrorReporting = () => {
  const { currentUser } = useSelector(state => state.auth);

  useEffect(() => {
    if (!RAYGUN_API_KEY) return;

    const user = currentUser
      ? {
          identifier: currentUser.id,
          isAnonymous: false,
          email: currentUser.email,
          fullName: currentUser.name
        }
      : { isAnonymous: true };

    rg4js("setUser", user);
  }, [currentUser]);
};

export const useTrackPageViewOnLocationChange = () => {
  const history = useHistory();

  useEffect(() => {
    if (!RAYGUN_API_KEY) return;

    const unlisten = history.listen(location =>
      rg4js("trackEvent", { type: "pageView", path: location?.pathname })
    );
    return () => unlisten();
  }, [history]);
};
