import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  flashDetailedToastNotification,
  flashToastNotification
} from "../../features/toast-notification/toastNotificationSlice";
import {
  upsertUserAsync,
  clearError,
  quickCreateUserTagAsync
} from "../../features/user/userSlice";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useGroupOptions } from "../../features/group/groupSlice";
import Multiselect from "../general/Multiselect";
import ImageUploaderFormField from "./utils/ImageUploaderFormField";
import axios from "axios";
import moment from "moment-timezone";
import { Label } from "./utils/Common";
import Button from "../common/Button";

const UserForm = ({ model, closeModal, handleSubmitted }) => {
  const { currentUser } = useSelector(state => state.auth);
  const [state, setState] = useState({
    ...model,
    PlatformId: currentUser.PlatformId,
    GroupIds: (model?.Groups || []).reduce((acc, group) => {
      acc.push(group.id);
      return acc;
    }, []),
    TagIds: (model?.UserTags || []).reduce((acc, tag) => {
      acc.push(tag.id);
      return acc;
    }, [])
  }); // form state

  const { error, generalError } = useSelector(state => state.user);

  const groupOptions = useGroupOptions();

  const isCreator = currentUser.role === "creator";
  const isAdmin = currentUser.role === "admin";
  const isEditingOwnProfile = state.id === currentUser.id;
  const isEditingCreatorAndAbove = state.role === "creator" || state.role === "admin";

  const isAllowedToConnectZoom = isEditingOwnProfile && (isCreator || isAdmin);
  const shouldShowZoomButtons = process.env.REACT_APP_ZOOM_API_KEY && isAllowedToConnectZoom;
  const resetZoomToken = () => setState(prevState => ({ ...prevState, zoom_access_token: "" }));

  const dispatch = useDispatch();
  const onChange = event => {
    const { name, value } = event.target;

    if (error && error[name]) {
      dispatch(clearError({ field: name }));
    }

    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSubmit = event => {
    event.preventDefault();
    const user = {
      ...state,
      role: state.role || "free",
      PlatformId: currentUser.PlatformId
    };
    delete user.Groups;
    dispatch(upsertUserAsync({ user })).then(action => {
      if (action.error) return;
      if (handleSubmitted) handleSubmitted();
      dispatch(flashToastNotification({}));
      closeModal();
    });
  };

  const onForgotPassword = async () => {
    let notification;
    try {
      await axios.post(`/auth/forgot-password`, {
        email: state.email,
        PlatformId: state.PlatformId,
        windowLocation: `${window.location.protocol}//${window.location.host}/`
      });
      notification = { details: "Password reset email sent" };
    } catch (err) {
      const { status, data } = err.response;
      if (status === 404) {
        notification = { details: "User not found", type: "error" };
      } else if (status === 400 && data.includes("Reset token has not expired yet")) {
        notification = { details: "Password request was just made" };
      } else {
        notification = { details: "Something went wrong", type: "error" };
      }
    }
    dispatch(flashDetailedToastNotification(notification));
  };

  /* Tags */

  const isTagLoading = useSelector(state => state.user.loading);

  const tagsOptions = useSelector(state =>
    state.user.userTags?.map(tag => ({ id: tag.id, label: tag.title }))
  );

  /*useEffect(() => {
    if (model) {
      setState(prevState => ({
        ...prevState,
        TagIds: (model.Tags || []).reduce((acc, tag) => {
          acc.push(tag.id);
          return acc;
        }, [])
      }));
    }
    // eslint-disable-next-line
  }, []);*/

  const tagQuickCreateHandler = title =>
    dispatch(quickCreateUserTagAsync({ title, PlatformId: state.PlatformId }));

  /* end tags */

  return (
    <form onSubmit={event => onSubmit(event)} id="user-form">
      <div className="flex flex-row justify-between ">
        <div className="w-1/2">
          {generalError && (
            <p className="text-sm leading-5 font-medium text-red-800 mb-1">{generalError}</p>
          )}
          {state.id && <span className="italic opacity-30">User ID: {state.id}</span>}
          <div id="name-input-div" className="mt-4">
            <Label htmlFor="name">Name</Label>
            <div className="mt-1 rounded-md text-gray-800 shadow-sm relative">
              <input
                required
                id="name"
                autoFocus
                name="name"
                className={`${
                  error && error.name
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.name) || ""}
                onChange={event => onChange(event)}
                placeholder="Name"
              />
              {error && error.name && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="w-5 text-red-600" />
                </div>
              )}
            </div>
            {error && error.name && (
              <p className="mt-2 text-sm text-red-600">This field is required</p>
            )}
          </div>
          <div className="mt-6" id="email-input-div">
            <Label htmlFor="email">Email</Label>
            <div className="mt-1 rounded-md  text-gray-800 shadow-sm relative">
              <input
                id="email"
                name="email"
                className={`${
                  error && error.email
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.email) || ""}
                onChange={event => onChange(event)}
                placeholder="email@test.com"
              />
              {error && error.email && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="w-5 text-red-600" />
                </div>
              )}
            </div>
            {error && error.email && (
              <p className="mt-2 text-sm text-red-600">This field is required</p>
            )}
          </div>

          {state.id ? (
            <button
              className="mt-8 w-full inline-flex justify-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-slate-700 bg-white hover:text-slate-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out"
              type="button"
              onClick={onForgotPassword}
              id="send-password-reset-email-button"
            >
              Send password reset email
            </button>
          ) : (
            <div className="mt-6" id="password-input-div">
              <Label htmlFor="password">Password</Label>
              <div className="mt-1 text-gray-800 rounded-md shadow-sm relative">
                <input
                  id="password"
                  name="password"
                  type="password"
                  className={`${
                    error && error.password
                      ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                      : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                  } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                  value={(state && state.password) || ""}
                  onChange={event => onChange(event)}
                  placeholder="password"
                />
                {error && error.password && (
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon className="w-5 text-red-600" />
                  </div>
                )}
              </div>
              {error && error.password && (
                <p className="mt-2 text-sm text-red-600">This field is required</p>
              )}
            </div>
          )}
          {currentUser.role === "admin" && (
            <div className="mt-6" id="stripe-id-input-div">
              <Label htmlFor="stripeCustomerId">Stripe Customer ID</Label>
              <div className="mt-1 text-gray-800 rounded-md shadow-sm relative">
                <input
                  id="stripeCustomerId"
                  name="stripeCustomerId"
                  type="text"
                  className={`${
                    error && error.stripeCustomerId
                      ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                      : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                  } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                  value={(state && state.stripeCustomerId) || ""}
                  onChange={event => onChange(event)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="w-1/2 pl-6">
          <div className="mt-9 rounded-md shadow-sm relative" id="avatar-uploader-div">
            <ImageUploaderFormField
              title="Avatar"
              field="photoUrl"
              description="SVG, PNG, JPG, GIF up to 10MB"
              value={state.photoUrl}
              onChange={value => onChange({ target: { name: "photoUrl", value } })}
            />
          </div>
          {shouldShowZoomButtons && (
            <ZoomConnectionButtons
              hasZoomAccessToken={!!state?.zoom_access_token}
              userId={state?.id}
              resetZoomToken={resetZoomToken}
            />
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-8 mt-4">
        <div id="city-input-div">
          <Label htmlFor="city">City</Label>
          <div className="mt-1 rounded-md text-gray-800 shadow-sm relative">
            <input
              id="city"
              name="city"
              className={`${
                error && error.city
                  ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                  : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
              } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              value={(state && state.city) || ""}
              onChange={event => onChange(event)}
              placeholder="City"
            />
            {error && error.city && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="w-5 text-red-600" />
              </div>
            )}
          </div>
          {error && error.city && (
            <p className="mt-2 text-sm text-red-600">This field is required</p>
          )}
        </div>
        <div id="state-input-div">
          <Label htmlFor="state">State</Label>
          <div className="mt-1 rounded-md text-gray-800 shadow-sm relative">
            <input
              id="state"
              name="state"
              className={`${
                error && error.state
                  ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                  : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
              } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
              value={(state && state.state) || ""}
              onChange={event => onChange(event)}
              placeholder="State"
            />
            {error && error.state && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="w-5 text-red-600" />
              </div>
            )}
          </div>
          {error && error.state && (
            <p className="mt-2 text-sm text-red-600">This field is required</p>
          )}
        </div>
      </div>
      <div className="mt-6" id="about-input-div">
        <Label htmlFor="notes">About / Description</Label>
        <div className="mt-1 text-gray-800 rounded-md shadow-sm relative">
          <textarea
            id="notes"
            name="notes"
            type="textarea"
            className={`${
              error && error.notes
                ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
            } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
            value={(state && state.notes) || ""}
            onChange={event => onChange(event)}
            placeholder="Tell us about yourself..."
          />
          {error && error.notes && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon className="w-5 text-red-600" />
            </div>
          )}
        </div>
        {error && error.notes && (
          <p className="mt-2 text-sm text-red-600">This field is required</p>
        )}
      </div>
      {isAdmin && (
        <>
          <div className="mt-6 text-gray-800" id="groups-select-div">
            <Label htmlFor="GroupIds">Groups</Label>
            <Multiselect
              id="GroupIds"
              name="GroupIds"
              value={(state && state.GroupIds) || []}
              onChange={onChange}
              multiple
              options={groupOptions}
            />
          </div>
          <div className="mt-6" id="tags-select-div">
            <Label htmlFor="TagIds">Tags</Label>
            <Multiselect
              id="TagIds"
              name="TagIds"
              options={tagsOptions}
              value={state.TagIds || []}
              onChange={onChange}
              placeholder="Choose or create tags"
              searchPlaceholder="Search or create"
              customActionText="Create new tag"
              customActionHandler={tagQuickCreateHandler}
              isCustomActionLoading={isTagLoading}
            />
          </div>
          <div className="mt-6" id="additional-info-input-div">
            <Label htmlFor="additionalInfo">Additional Info (Users will not see this)</Label>
            <div className="mt-1 text-gray-800 rounded-md shadow-sm relative">
              <textarea
                id="additionalInfo"
                name="additionalInfo"
                type="textarea"
                className={`${
                  error && error.additionalInfo
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.additionalInfo) || ""}
                onChange={event => onChange(event)}
                placeholder="Tell us about yourself..."
              />
              {error && error.additionalInfo && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="w-5 text-red-600" />
                </div>
              )}
            </div>
            {error && error.additionalInfo && (
              <p className="mt-2 text-sm text-red-600">This field is required</p>
            )}
          </div>
          {isEditingCreatorAndAbove && (
            <div className="mt-6" id="custom-analytics-url-input-div">
              <Label htmlFor="customAnalyticsUrl">Custom Analytics URL</Label>
              <div className="mt-1 text-gray-800 rounded-md shadow-sm relative">
                <input
                  id="customAnalyticsUrl"
                  name="customAnalyticsUrl"
                  type="textarea"
                  className={`block w-full px-3 py-2 border rounded-md border-gray-300 focus:shadow-outline-blue focus:border-blue-300 focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                  value={(state && state.customAnalyticsUrl) || ""}
                  onChange={event => onChange(event)}
                />
              </div>
            </div>
          )}
          <div className="mt-6 hidden" id="user-position-input-div">
            <Label htmlFor="position">Position (Users will not see this)</Label>
            <div className="mt-1 text-gray-800 rounded-md shadow-sm relative">
              <input
                id="position"
                name="position"
                type="number"
                className={`${
                  error && error.position
                    ? "border-red-300 text-red-300 focus:border-red-300 focus:shadow-outline-red"
                    : "border-gray-300 focus:shadow-outline-blue focus:border-blue-300"
                } block w-full px-3 py-2 border rounded-md focus:outline-none transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
                value={(state && state.position) || ""}
                onChange={event => onChange(event)}
              />
              {error && error.position && (
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon className="w-5 text-red-600" />
                </div>
              )}
            </div>
            {error && error.position && (
              <p className="mt-2 text-sm text-red-600">This field is required</p>
            )}
          </div>
        </>
      )}

      {isAdmin && !isEditingOwnProfile && (
        <div className="mt-6" id="role-select-div">
          <Label htmlFor="role">Role</Label>
          <div className="mt-1 rounded-md shadow-sm relative">
            <select
              id="role"
              className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 border border-gray-300"
              name="role"
              value={(state && state.role) || ""}
              onChange={event => onChange(event)}
            >
              <option value="free">Free</option>
              <option value="basic">Basic</option>
              <option value="premium">Premium</option>
              <option value="creator">Creator</option>
              <option value="admin">Admin</option>
            </select>
          </div>

          {error && error.role && (
            <p className="mt-2 text-sm text-red-600">This field is required</p>
          )}
        </div>
      )}

      {isAdmin && !isEditingOwnProfile && (
        <div className="mt-6" id="banned-checkbox-div">
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id="banned"
                type="checkbox"
                className="form-checkbox h-4 w-4 text-slate-700 transition duration-150 ease-in-out"
                name="banned"
                checked={(state && state.banned) || false}
                onChange={() => {
                  setState(prevState => ({
                    ...prevState,
                    banned: !state.banned
                  }));
                }}
              />
            </div>
            <Label htmlFor="banned" checkbox>
              Banned
            </Label>
          </div>
        </div>
      )}

      <div className="mt-6" id="cover-photo-uploader-div">
        <div className="mt-1 rounded-md shadow-sm relative">
          <ImageUploaderFormField
            title="Upload / Change your Cover Photo"
            field="bannerImageUrl"
            description="SVG, PNG, JPG, GIF up to 10MB"
            value={state.bannerImageUrl}
            instructions={"For best results, upload an image that is 1230px x 300px "}
            onChange={value => onChange({ target: { name: "bannerImageUrl", value } })}
          />
        </div>
      </div>

      <div className="mt-8 grid grid-cols-2 gap-3">
        <Button onClick={closeModal} variant="secondary">
          Cancel
        </Button>
        <Button type="submit" variant="primary">
          Save
        </Button>
      </div>
    </form>
  );
};

const ZoomConnectionButtons = ({ hasZoomAccessToken, userId, resetZoomToken }) => {
  const dispatch = useDispatch();

  const revokeZoomAccess = async () => {
    try {
      await axios.get("/api/zoom/revoke-access/");
      resetZoomToken();
      dispatch(flashToastNotification({}));
    } catch (error) {
      console.error("Failed to revoke zoom access", error);
    }
  };

  const initiateZoomAuth = () => {
    window.location.href = `${
      process.env.REACT_APP_ZOOM_MICRO_SERVICE
    }zoom/authorize/${userId}?t=${moment().valueOf()}`;
    return true;
    //window.open(`${process.env.REACT_APP_ZOOM_MICRO_SERVICE}zoom/authorize/${state.id}`);
    //window.location.reload();
  };

  return hasZoomAccessToken ? (
    <div className="mt-6">
      <button
        type="button"
        onClick={revokeZoomAccess}
        className="mt-2 inline-flex items-center px-2.5 py-1.5 border border-red-300 text-xs leading-4 font-medium rounded text-red-300 bg-white hover:text-red-200 focus:outline-none focus:border-red-200 focus:shadow-outline-redd active:text-red-200 active:bg-gray-50 transition ease-in-out duration-150"
      >
        Disconnect Zoom
      </button>
    </div>
  ) : (
    <div className="mt-6">
      <button
        type="button"
        onClick={initiateZoomAuth}
        className="mt-2 inline-flex items-center px-2.5 py-1.5 border border-blue-300 text-xs leading-4 font-medium rounded text-blue-300 bg-white hover:text-blue-200 focus:outline-none focus:border-blue-200 focus:shadow-outline-blue active:text-blue-200 active:bg-gray-50 transition ease-in-out duration-150"
      >
        Connect Zoom
      </button>
    </div>
  );
};

export default UserForm;
