import { TwoColumnLayout } from "./TwoColumnLayout";
import { CollectionProvider } from "../../general/currentCollectionContext";
import { LeftNavbarVisibleProvider } from "../../general/LeftNavbarVisibleContext";

const MainPageWithSideBar = ({ children }) => {
  return (
    <LeftNavbarVisibleProvider>
      <CollectionProvider>
        <TwoColumnLayout>{children}</TwoColumnLayout>
      </CollectionProvider>
    </LeftNavbarVisibleProvider>
  );
};

export default MainPageWithSideBar;
