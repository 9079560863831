import React from "react";
import { Link } from "react-router-dom";

const ContentLink = ({ isExternal, isInternal, to, children }) => {
  const target = isExternal ? "_blank" : "_self";

  return isExternal || isInternal ? (
    <a href={to} target={target} rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <Link to={to}>{children}</Link>
  );
};

export default ContentLink;
