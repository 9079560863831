import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { useState } from "react";

function Tooltip({ content, position = "top" }) {
  const [isVisible, setIsVisible] = useState(false);

  const positionClasses = {
    top: "bottom-full left-1/2 transform -translate-x-1/2 mb-2",
    right: "left-full top-1/2 transform -translate-y-1/2 ml-2",
    bottom: "top-full left-1/2 transform -translate-x-1/2 mt-2",
    left: "right-full top-1/2 transform -translate-y-1/2 mr-2"
  };

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        className="inline-block"
      >
        <QuestionMarkCircleIcon className="h-5 mr-2 text-gray-500 inline" />
      </div>
      {isVisible && (
        <div
          className={`absolute z-10 px-3 py-2 w-64 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm ${positionClasses[position]}`}
          role="tooltip"
        >
          {content}
          <div
            className={`absolute w-2 h-2 bg-gray-900 transform rotate-45 ${
              position === "top"
                ? "bottom-[-4px] left-1/2 -translate-x-1/2"
                : position === "right"
                ? "left-[-4px] top-1/2 -translate-y-1/2"
                : position === "bottom"
                ? "top-[-4px] left-1/2 -translate-x-1/2"
                : "right-[-4px] top-1/2 -translate-y-1/2"
            }`}
          />
        </div>
      )}
    </div>
  );
}

export default Tooltip;
