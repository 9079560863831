import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../features/auth/authSlice";
import axios from "axios";
import { flashDetailedToastNotification } from "../../features/toast-notification/toastNotificationSlice";
import { useAuthPrompt } from "../../features/auth/authHooks";

const ForgotPassword = ({ email }) => {
  const dispatch = useDispatch();
  const { dismissAuthPrompt } = useAuthPrompt();

  const { platformData } = useSelector(state => state.frontend);
  const { id: PlatformId } = platformData;

  const [state, setState] = useState({
    windowLocation: `${window.location.protocol}//${window.location.host}/`,
    PlatformId,
    email
  });
  const [passwordIsResetted, setPasswordIsResetted] = useState(false);

  const onChange = event => {
    dispatch(clearErrors());

    const { name, value } = event.target;

    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSubmit = async event => {
    event.preventDefault();

    try {
      await axios.post(`/auth/forgot-password`, state);
      setPasswordIsResetted(true);
    } catch (err) {
      let notification;
      const { status, data } = err.response;
      if (status === 404) {
        notification = { details: "User not found", type: "error" };
      } else if (status === 400 && data.includes("Reset token has not expired yet")) {
        notification = { details: "Password request was just made, please check your inbox" };
      } else {
        notification = { details: "Something went wrong", type: "error" };
      }
      dispatch(flashDetailedToastNotification(notification));
    }
  };

  return (
    <>
      {!passwordIsResetted ? (
        <form onSubmit={event => onSubmit(event)}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
              Email address
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                id="email"
                name="email"
                type="email"
                value={state.email || ""}
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                onChange={event => onChange(event)}
              />
            </div>
          </div>

          <div className="mt-6">
            <span className="block w-full rounded-md shadow-sm">
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-platform-primary focus:outline-none focus:shadow-outline-indigo transition duration-150 ease-in-out"
              >
                Reset password
              </button>
            </span>
          </div>
        </form>
      ) : (
        <>
          <p className="mt-6 text-center text-gray-900">Please check your email for next steps</p>
          <Link
            to={"/"}
            className="mt-6 w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-platform-primary focus:outline-none focus:shadow-outline-indigo transition duration-150 ease-in-out"
            onClick={() => dismissAuthPrompt()}
          >
            Take me Home
          </Link>
        </>
      )}
    </>
  );
};

export default ForgotPassword;
