import Tooltip from "../../common/Tooltip";

export function Label({ htmlFor, label, children, checkbox, tooltipContent }) {
  const classes = checkbox ? checkboxLabelClasses : inputLabelClasses;

  return (
    <label htmlFor={htmlFor} className={classes}>
      {tooltipContent ? (
        <div className="flex items-center">
          <span className="inline-block mr-2">{label || children}</span>
          <Tooltip content={tooltipContent} position="right" />
        </div>
      ) : (
        label || children
      )}
    </label>
  );
}

const inputLabelClasses = "block mb-1 text-sm font-medium leading-5 text-slate-700";
const checkboxLabelClasses = "ml-3 text-sm leading-5 font-medium text-slate-700";
