import React, { useEffect, lazy, Suspense } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlatformContentAsync,
  getPublicCollectionsAsync,
  goToInfinitePaginationNextPage,
  useLoadPlatformDataAndCurrentUser
} from "./features/frontend/frontendSlice";
import AuthModal from "./components/auth/AuthModal";
import * as Fathom from "fathom-client";
import TopNavbar from "./components/pages/layout/TopNavbar";
import ToastNotification from "./components/pages/dashboard/general/ToastNotification";
import { LoadingSpinnerCentered } from "./components/LoadingSpinner";
import MainPageWithSideBar from "./components/pages/layout/MainPageWithSideBar";
import { Body } from "./components/pages/layout/Body";
import LeftNavbarWithRouter from "./components/pages/layout/LeftNavbar";
import { useWatchCurrentUserForErrorReporting } from "./utils/errorReporting";
import { lazyImportWrapper } from "./utils/lazy-loading";

const ZoomAuth = lazy(() =>
  lazyImportWrapper(() => import(/* webpackChunkName: "ZoomAuth" */ "./components/pages/ZoomAuth"))
);
const ZoomNotify = lazy(() =>
  lazyImportWrapper(() =>
    import(/* webpackChunkName: "ZoomNotify" */ "./components/pages/ZoomNotify")
  )
);
const FrontPage = lazy(() =>
  lazyImportWrapper(() =>
    import(/* webpackChunkName: "FrontPage" */ "./components/pages/FrontPage")
  )
);
const GroupPage = lazy(() =>
  lazyImportWrapper(() =>
    import(/* webpackChunkName: "GroupPage" */ "./components/pages/groups/GroupPage")
  )
);
const ContentPage = lazy(() =>
  lazyImportWrapper(() =>
    import(/* webpackChunkName: "ContentPage" */ "./components/pages/ContentPage")
  )
);
const GroupsListPage = lazy(() =>
  lazyImportWrapper(() =>
    import(/* webpackChunkName: "GroupsListPage" */ "./components/pages/groups/GroupsListPage")
  )
);
const AuthPage = lazy(() =>
  lazyImportWrapper(() => import(/* webpackChunkName: "AuthPage" */ "./components/pages/AuthPage"))
);
const AuthMagicGroup = lazy(() =>
  lazyImportWrapper(() =>
    import(/* webpackChunkName: "AuthMagicGroup" */ "./components/pages/AuthMagicGroup")
  )
);
const EmbedPage = lazy(() =>
  lazyImportWrapper(() =>
    import(/* webpackChunkName: "EmbedPage" */ "./components/pages/EmbedPage")
  )
);
const CreatorsPage = lazy(() =>
  lazyImportWrapper(() =>
    import(/* webpackChunkName: "CreatorsPage" */ "./components/pages/CreatorsPage")
  )
);
const ResetPasswordPage = lazy(() =>
  lazyImportWrapper(() =>
    import(/* webpackChunkName: "PasswordResetPage" */ "./components/pages/ResetPasswordPage")
  )
);
const TagsSearchResultsPage = lazy(() =>
  lazyImportWrapper(() =>
    import(
      /* webpackChunkName: "TagsSearchResultsPage" */ "./components/pages/TagsSearchResultsPage"
    )
  )
);
const CollectionPage = lazy(() =>
  lazyImportWrapper(() =>
    import(/* webpackChunkName: "CollectionPage" */ "./components/pages/CollectionPage")
  )
);
const SearchResultsPage = lazy(() =>
  lazyImportWrapper(() =>
    import(/* webpackChunkName: "SearchResultsPage" */ "./components/pages/SearchResultsPage")
  )
);
const TopicPage = lazy(() =>
  lazyImportWrapper(() =>
    import(/* webpackChunkName: "TopicPage" */ "./components/pages/TopicPage")
  )
);
const ContentChatOnly = lazy(() =>
  lazyImportWrapper(() =>
    import(/* webpackChunkName: "ContentChatOnly" */ "./components/pages/ContentChatOnly")
  )
);
const ProfilePage = lazy(() =>
  lazyImportWrapper(() =>
    import(/* webpackChunkName: "ProfilePage" */ "./components/pages/ProfilePage")
  )
);
const DashboardLayout = lazy(() =>
  lazyImportWrapper(() =>
    import(
      /* webpackChunkName: "DashboardLayout" */ "./components/pages/dashboard/general/DashboardLayout"
    )
  )
);
const ErrorPage404 = lazy(() =>
  lazyImportWrapper(() =>
    import(/* webpackChunkName: "ErrorPage404" */ "./components/pages/404/ErrorPage404")
  )
);

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { platformData } = useSelector(state => state.frontend);
  const { currentUser } = useSelector(state => state.auth);

  useWatchCurrentUserForErrorReporting();
  // Raygun RUM disabled for now
  // useTrackPageViewOnLocationChange();

  useLoadPlatformDataAndCurrentUser();

  useRedirectToDashboardIfPlatformIsDashboardOnly(location, platformData);

  // FATHOM ANALYTICS Upon initial page load...
  useEffect(() => {
    if (platformData.fathomAnalytics && !location.pathname.includes("dashboard")) {
      Fathom.load(platformData.fathomAnalytics);

      // In the route changed event handler...
      // const onRouteChangeComplete = () => {
      //   Fathom.trackPageview();
      // };
    }
  }, [platformData.fathomAnalytics, location.pathname]);

  // LOAD CUSTOM CSS and JS
  useEffect(() => {
    const isOnEmbedPage = location.pathname.includes("embed");
    const wasCustomCodeAlreadyInitialised = document.getElementById("custom-css");
    if (platformData.id && !wasCustomCodeAlreadyInitialised && !isOnEmbedPage) {
      if (platformData.customCSS) {
        const style = document.createElement("style");
        style.id = "custom-css";
        style.textContent = platformData.customCSS;
        document.head.appendChild(style);
      }

      if (platformData.customJS) {
        // Load the script tags before the closing <body>
        const fragment = document.createRange().createContextualFragment(platformData.customJS);
        document.body.appendChild(fragment);
      }
    }
  }, [dispatch, platformData, location.pathname]);

  useEffect(() => {
    if (!platformData || platformData.loading || platformData.error || !platformData.id) {
      return;
    }

    dispatch(getPlatformContentAsync({ platformData }));
    dispatch(goToInfinitePaginationNextPage());
    dispatch(getPublicCollectionsAsync({ platformData, itemsPerPage: 4 }));
  }, [dispatch, platformData]);

  useEffect(() => {
    let script1 = document.querySelector(`script[id="beacon-v2"]`);
    let script2 = document.querySelector(`script[id="beacon-init"]`);
    let script3 = document.querySelector("#beacon-container");
    const isOnEmbedPage = location.pathname.includes("embed");
    const isAdmin = currentUser?.role === "admin";
    if (isAdmin && !isOnEmbedPage) {
      if (!script1) {
        script1 = document.createElement("script");
        script1.id = "beacon-v2";
        script1.type = "text/javascript";
        script1.innerText =
          '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});';
        document.body.appendChild(script1);
      }
      if (!script2) {
        script2 = document.createElement("script");
        script2.id = "beacon-init";
        script2.type = "text/javascript";
        script2.innerText = "window.Beacon('init', 'a44bb5b7-bf25-43df-abae-be9eac3250ea')";
        document.body.appendChild(script2);
      }
    } else {
      if (script1) script1.remove();
      if (script2) script2.remove();
      if (script3) script3.remove();
    }
  }, [currentUser, location.pathname]);

  return (
    <Body>
      <TopNavbar />
      <MainPageWithSideBar>
        <LeftNavbarWithRouter />
        <ToastNotification />
        <Suspense fallback={<LoadingSpinnerCentered />}>
          <Switch location={location}>
            <Route exact path="/" children={<FrontPage />} />
            <Route exact path="/groups/:slug" component={GroupPage} />
            <Route
              exact
              path="/groups/:slug/:collection_slug/:content_id"
              component={ContentPage}
            />
            <Route exact path="/groups" component={GroupsListPage} />
            <Route path="/creators" children={<CreatorsPage />} />
            <Route exact path="/reset-password" children={<ResetPasswordPage />} />
            <Route path="/search" children={<SearchResultsPage />} />
            <Route path="/tags/:tag" children={<TagsSearchResultsPage />} />
            <Route exact path="/auth-group/:form/:groupID?" component={AuthMagicGroup} />
            <Route exact path="/auth/:form/:role?" component={AuthPage} />
            <Route exact path="/zoom/authorized" component={ZoomAuth} />
            <Route exact path="/zoom/notify" component={ZoomNotify} />
            <Route path="/dashboard" children={<DashboardLayout />} />
            <Route exact path="/viewcontent/:content_id" component={ContentPage} />
            <Route exact path="/topic/:topic_slug" children={<TopicPage />} />
            <Route exact path="/profile/:userId" component={ProfilePage} />
            <Route exact path="/embed/:content_id" component={EmbedPage} />

            <Route exact path="/:collection_slug" children={<CollectionPage />} />
            <Route exact path="/:collection_slug/:content_id" component={ContentPage} />
            <Route exact path="/:collection_slug/:content_id/chat" children={<ContentChatOnly />} />

            <Route path="*">
              <ErrorPage404 />
            </Route>
          </Switch>
        </Suspense>
      </MainPageWithSideBar>
      <AuthModal />
    </Body>
  );
};

function useRedirectToDashboardIfPlatformIsDashboardOnly(location, platformData) {
  useEffect(() => {
    const exemptPaths = ["/embed", "/reset-password", "/auth-group", "/viewcontent"];
    const isOnExemptPath = exemptPaths.some(path => location.pathname.includes(path));
    if (isOnExemptPath) return;
    if (platformData.dashboardOnly && !location.pathname.includes("dashboard")) {
      window.location.href = "/dashboard";
    }
  }, [location.pathname, platformData]);
}

export default App;
