import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  composeQueryParams,
  initialDashboardPageMetadata,
  mergeDshbPageMetadataObjects,
  paramsSerializer
} from "../../utils";

export const readAllLinksForDashboardAsync = createAsyncThunk(
  "link/readAll",
  async (payload = {}, { getState }) => {
    try {
      const { metadata } = getState().link;
      const params = composeQueryParams(payload, metadata);
      const response = await axios.get("/api/admin/links", { params, paramsSerializer });
      return { links: response.data };
    } catch (error) {
      console.log(error);
    }
  }
);

export const upsertLinkAsync = createAsyncThunk(
  "link/upsert",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/api/link`, payload.link);
      return { link: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const deleteLinkAsync = createAsyncThunk("/link/delete", async payload => {
  await axios.delete(`/api/link/${payload.link.id}`);
  return { id: payload.link.id };
});

export const linkSlice = createSlice({
  name: "link",
  initialState: {
    loading: true,
    error: null,
    links: [],
    metadata: initialDashboardPageMetadata
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(readAllLinksForDashboardAsync.pending, state => {
        state.loading = true;
      })
      .addCase(readAllLinksForDashboardAsync.fulfilled, (state, action) => {
        state.loading = false;
        const { items, ...metadata } = action.payload.links || {};
        state.links = items || [];
        state.metadata = mergeDshbPageMetadataObjects(state.metadata, metadata);
      })
      .addCase(readAllLinksForDashboardAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(upsertLinkAsync.pending, state => {
        state.loading = true;
      })
      .addCase(upsertLinkAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.link[1]) {
          // insert
          if (action.payload.link[0]) state.links.unshift(action.payload.link[0]);
        } else {
          // update
          state.links = (state.links || []).map(link =>
            link.id === action.payload.link[0].id ? action.payload.link[0] : link
          );
        }
      })
      .addCase(upsertLinkAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload || []).reduce((acc, error) => {
          acc[error.path] = `The ${error.path} field cannot be empty`;
          return acc;
        }, {});
      })
      .addCase(deleteLinkAsync.pending, state => {
        state.loading = true;
      })
      .addCase(deleteLinkAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.links = state.links.filter(link => link.id !== action.payload.id);
      })
      .addCase(deleteLinkAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  }
});

export const { clearError, clearErrors } = linkSlice.actions;
export default linkSlice.reducer;
