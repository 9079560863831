import { createContext, useState } from "react";

export const CollectionContext = createContext(null);

export const CollectionProvider = ({ children }) => {
  const [collection, setCollection] = useState({});
  const [pageStatus, setPageStatus] = useState(200);

  return (
    <CollectionContext.Provider
      value={{
        collection,
        setCollection,
        pageStatus,
        setPageStatus
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
};
