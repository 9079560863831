import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import slugify from "slugify";
import axios from "axios";
import {
  composeQueryParams,
  initialDashboardPageMetadata,
  mergeDshbPageMetadataObjects,
  paramsSerializer
} from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export const useCollectionOptions = () => {
  const dispatch = useDispatch();
  const collectionOptions = useSelector(selectCollectionOptions);
  const { currentUser } = useSelector(state => state.auth);

  useEffect(() => {
    if (currentUser.role !== "creator" && currentUser.role !== "admin") return;
    if (!collectionOptions.length) {
      dispatch(getCollectionOptionsAsync());
    }
    // ignore collectionOptions as it will be updated by getCollectionOptionsByPlatformAsync (avoid infinite re-renders)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentUser]);

  return collectionOptions;
};

// selectors

export const selectCollectionOptions = state => {
  const { currentUser } = state.auth;
  let collections = state.collection.collectionOptions;
  // creators should only see their own collections
  if (currentUser.role === "creator")
    collections = collections.filter(col => col.UserId === currentUser.id);
  return collections.map(col => ({ id: col.id, label: col.name }));
};

// actions

export const readAllCollectionsForDashboardAsync = createAsyncThunk(
  "collection/readAllForDashboard",
  async (payload = {}, { getState }) => {
    try {
      const { metadata } = getState().collection;
      const params = composeQueryParams(payload, metadata);
      const response = await axios.get("/api/admin/collections", { params, paramsSerializer });
      return { collections: response.data };
    } catch (error) {
      console.log(error);
    }
  }
);

export const getCollectionOptionsAsync = createAsyncThunk(
  "/collection/getCollectionOptions",
  async () => {
    const response = await axios.get(`/api/collection-options`);
    return { collections: response.data };
  }
);

export const upsertCollectionAsync = createAsyncThunk(
  "collection/upsert",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`/api/collection`, payload.collection);
      dispatch(getCollectionOptionsAsync());
      return { collection: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const quickCollectionCreateAsync = createAsyncThunk(
  "collection/quickCreate",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const { frontend, auth } = getState();
    const platformId = payload.PlatformId || frontend.platformData.id;
    const body = {
      name: payload.name,
      slug: slugify(payload.name),
      PlatformId: platformId,
      UserId: auth.currentUser.id
    };
    try {
      const response = await axios.post(`/api/collection`, body);
      dispatch(getCollectionOptionsAsync());
      return { collection: response.data };
    } catch (error) {
      return rejectWithValue(error.response.data.errors);
    }
  }
);

export const deleteCollectionAsync = createAsyncThunk(
  "/collection/delete",
  async (payload, { dispatch }) => {
    await axios.delete(`/api/collection/${payload.collection.id}`);
    dispatch(getCollectionOptionsAsync());
    return { id: payload.collection.id };
  }
);

export const collectionSlice = createSlice({
  name: "collection",
  initialState: {
    loading: false,
    error: null,
    collections: [],
    collectionOptions: [],
    metadata: initialDashboardPageMetadata
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(readAllCollectionsForDashboardAsync.pending, state => {
        state.loading = true;
      })
      .addCase(readAllCollectionsForDashboardAsync.fulfilled, (state, action) => {
        state.loading = false;
        const { items, ...metadata } = action.payload.collections || {};
        state.collections = items || [];
        state.metadata = mergeDshbPageMetadataObjects(state.metadata, metadata);
      })
      .addCase(readAllCollectionsForDashboardAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getCollectionOptionsAsync.fulfilled, (state, action) => {
        state.collectionOptions = action.payload.collections;
      })
      .addCase(upsertCollectionAsync.pending, state => {
        state.loading = true;
      })
      .addCase(quickCollectionCreateAsync.pending, state => {
        state.loading = true;
      })
      .addCase(quickCollectionCreateAsync.fulfilled, state => {
        state.loading = false;
      })
      .addCase(quickCollectionCreateAsync.rejected, state => {
        state.loading = false;
      })
      .addCase(upsertCollectionAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.collection[1]) {
          // insert
          if (action.payload.collection[0]) state.collections.unshift(action.payload.collection[0]);
        } else {
          // update
          state.collections = (state.collections || []).map(collection =>
            collection.id === action.payload.collection[0].id
              ? action.payload.collection[0]
              : collection
          );
        }
      })
      .addCase(upsertCollectionAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload || []).reduce((acc, error) => {
          acc[error.path] = `The ${error.path} field cannot be empty`;
          return acc;
        }, {});
      })
      .addCase(deleteCollectionAsync.pending, state => {
        state.loading = true;
      })
      .addCase(deleteCollectionAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.collections = state.collections.filter(
          collection => collection.id !== action.payload.id
        );
      })
      .addCase(deleteCollectionAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  }
});
export const { clearError, clearErrors } = collectionSlice.actions;
export default collectionSlice.reducer;
