import React from "react";
import { useSelector } from "react-redux";
import ContentComplete from "../content/ContentComplete";
import ContentLink from "../../general/ContentLink";

const CollectionContentList = ({ collection }) => {
  const { platformData } = useSelector(state => state.frontend);

  return (
    <ul className="mx-3 md:mx-auto max-w-6xl px-0 pb-6">
      {(collection.Contents || []).map((content, i) => {
        const link =
          content.type === "internallink"
            ? content.contentURI
            : `/${collection.slug || "viewcontent"}/${content.id}`;

        return (
          <li key={i} className=" flex my-4 space-x-2  flex-row  align-baseline ">
            {collection?.collectionType === "course" && (
              <ContentComplete
                content={content}
                buttonClass={"text-xs w-5 rounded-full h-5   "}
                platformData={platformData}
                iconOnly
              />
            )}
            <ContentLink isInternal={content.type === "internallink"} to={link}>
              <span className="font-sm text-ellipsis w-full opacity-80 hover:opacity-100  line-clamp  leading-tight text-sm sm:text-base flex items-center justify-between">
                {content.title}
              </span>
            </ContentLink>
          </li>
        );
      })}
      {collection.contentTotal > collection.Contents?.length && (
        <ContentLink to={`/${collection.slug}`}>Show more</ContentLink>
      )}
    </ul>
  );
};

export default CollectionContentList;
