import React from "react";

const LoadingSpinner = ({ size = "md", className }) => {
  const sizeClass = size === "md" ? "h-10 w-10" : "h-6 w-6";

  return (
    <svg
      className={`animate-spin ${sizeClass} text-gray-700 ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      data-testid="loading-spinner"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="3"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

export const LoadingSpinnerCentered = ({
  showing = true,
  fullHeight = true,
  platformColor = true
}) => {
  const heightClass = fullHeight ? "h-screen" : "h-48";

  if (!showing) return null;

  return (
    <div
      className={`${heightClass} w-full flex justify-around items-center ${
        platformColor ? "bg-platform-light-color" : ""
      }`}
    >
      <LoadingSpinner />
    </div>
  );
};

export default LoadingSpinner;
